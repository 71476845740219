.secondSlider{
    min-height: 475px;
}

.secondSlider .flickity-page-dots {
    text-align: left !important;
    bottom: 140px !important;
}

.secondSlider .flickity-page-dots .is-selected {
    background-color: #BF3F5D;
}

.secondSlider .flickity-page-dots li {
    background-color: #5D5D5D;
    opacity: 1;
}

@media only screen and (max-width:1200px) {
    .secondSlider .flickity-page-dots {
        text-align: center !important;
        bottom: -35px !important;
    }
}