#treatmentMethods span {
  align-items: center;
}

#treatmentMethods a {
  font-weight: 400;
  color: #4c84c1;
}

.linkActive {
  font-weight: 700 !important;
}

#textAreas ul {
  list-style-type: disc;
}

#textAreas ol li {
  margin: 0;
  margin-left: 40px;
}
