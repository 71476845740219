#smContactInfo {
  display: flex !important;
}

.contactInfo span {
  color: #b0b0b0;
}

#stemCellsMenu{
    min-height: 200px;
    padding: 75px;
    padding-left: 220px;
}

@media only screen and (max-width: 767px){
  #stemCellsMenu{
    min-height: 0;
    padding: 0;
    padding-left: 0;
  }
  .dropdown__content {
    background-color: rgb(230, 230, 230) !important;
  }
}

@media only screen and (max-width: 1200px) {
  #dropdownImage {
    width: 290px;
  }
}
