.productCard {
  position: relative;
}

.productCardContent {
  position: absolute;
  top: 4.4vw;
  right: 15vw;
}

#productCard2 {
  flex-direction: column;
  align-items: center;
}
