body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

a {
  text-decoration: none !important;
}

/* h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 4.94vw !important;
}

h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 3.97vw !important;
}

h3 {
  font-family: 'Inter';
  font-style: normal;
  font-size: 3.179vw !important;
}

h4 {
  font-family: 'Inter';
  font-style: normal;
  font-size: 2.543vw !important;
}

h5 {
  font-family: 'Inter';
  font-style: normal;
  font-size: 2.034vw !important;
}

h6 {
  font-family: 'Inter';
  font-style: normal;
  font-size: 1.628vw !important;
  margin: 0;
} */