#corporate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#corporate .borderBottom {
  padding-bottom: 20px;
  border-bottom: 1px solid black;
}
