.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bc {
  margin-left: 150px;
}

#mainSection {
  padding-top: 0;
}